.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  list-style: none;
  margin: 0;
  padding: 0;
  @include medium-up {
    display: flex;
    flex-wrap: wrap;
    margin: rem-calc(0 -15px);
  }

  .col {
    width: 100%;
  }

  li {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.two-column .col {
  @include medium-up {
    width: 50%;
    padding: rem-calc(0 15px 15px 15px);
  }
}

.three-column .col {
  @include medium-up {
    width: 50%;
    padding: rem-calc(0 15px 15px 15px);
  }
  @include large-up {
    width: 33.3%;
  }
}

.four-column .col {
  @include medium-up {
    width: 50%;
    padding: rem-calc(0 15px 15px 15px);
  }
  @include large-up {
    width: 25%;
  }
}

.five-column .col {
  @include medium-up {
    width: 50%;
    padding: rem-calc(0 15px 15px 15px);
  }
  @include large-up {
    width: 20%;
  }
}

.six-column .col {
  @include medium-up {
    width: 50%;
    padding: rem-calc(0 15px 15px 15px);
  }
  @include large-up {
    width: 16.66666666666667%;
  }
}