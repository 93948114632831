.subpage aside {
  padding-top: 40px;
  width: 100%;
  @include medium-up {
    padding: 50px 0 0 50px;
    width: 32%;
  }
  @include large-up {
    padding-left: 100px;
    padding-right: 0;
  }
}