.search-results {
  .search-results-search {
    background: $lightiron;
    overflow: hidden;

    [type='search'] {
      float: left;
      padding: 0 5px;
      width: calc(100% - 122px);
      height: 31px;
      border: 0;
      color: $limedspruce;
      font-family: $franklin;
      background: white;
      appearance: none;
      @include fontsize(16 16);
    }

    [type='submit'] {
      display: inline-block;
      float: right;
      cursor: pointer;
      margin: 0;
      width: 112px;
      height: 31px;
      background: $tallpoppy;
      color: white;
      @include fontsize(15 15);
      padding: 8px 24px;
      border: 0;
      font-weight: 700;
      text-transform: uppercase;
      height: auto;
      text-align: center;
      font-family: $franklin;

      &:hover,
      &:focus {
        border: 0;
        color: white;
        background: $cobalt;
      }
    }
  }

  .results {
    &:before {
      background: $bombay;
    }

    tbody tr {
      &:nth-child(odd) {
        background: rgba(white, 0.5);
      }

      &:nth-child(even) {
        background: $lightiron;
      }

      &.pagination {
        background: transparent;
      }

      &:nth-child(n+2):not(.pagination) > td {
        padding: 15px;

        a {
          margin-bottom: 5px;
          font-weight: 700;

          mark {
            background: rgba(black, 0.1);
          }
        }
      }
    }

    .pagination {
      border-color: $bombay;

      table tbody tr {
        background: transparent;
      }

      span,
      a {
        color: white;
        background: $cobalt;
        display: inline-block;
        text-decoration: none;
        padding: 8px 12px;
        border: 0;
        font-weight: 700;
        @include fontsize(13 13);
      }

      a {
        background: $limedspruce;
        color: white;

        &:visited {
          background: $limedspruce;
          color: white;
        }

        &:hover,
        &:focus {
          color: white;
          background: $tallpoppy;
        }
      }
    }
  }
}