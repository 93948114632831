.tabs {
  margin-bottom: 0;

  &.scroll-right:after {
    height: 35px;
    background: linear-gradient(to left, $athensgray 0%, rgba($athensgray, 0) 100%);
    @include medium-up {
      height: 47px;
    }
  }

  &.scroll-left:before {
    height: 35px;
    background: linear-gradient(to left, $athensgray 0%, rgba($athensgray, 0) 100%);
    @include medium-up {
      height: 47px;
    }
  }

  .tab-list {
    @include fontsize(15 15);

    li {
      margin-right: 2px;
      border-left: 0;

      a {
        padding: 10px 16px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        text-transform: uppercase;
        color: white;
        background: $tallpoppy;
        @include medium-up {
          padding: 16px 25px;
        }

        &:visited {
          color: white;
        }

        &[aria-selected] {
          padding: 10px 16px;
          color: white;
          background: $limedspruce;
          @include medium-up {
            padding: 16px 25px;
          }

          &:hover {
            color: white;
            background: $limedspruce;
          }
        }

        &:hover {
          color: white;
          background: $cobalt;
        }

        &[role='tab']:focus {
          color: white;
          background: $limedspruce;
        }

        &[aria-selected][role='tab']:focus {
          color: white;
          background: $limedspruce;
        }
      }
    }
  }

  .tab-content {
    margin-bottom: 20px;
    padding: 20px;
    border-top: 5px solid $limedspruce;
    @include fontsize(15 24);
    background: $lightiron;
    @include medium-up {
      padding: 25px;
    }

    p,
    ul,
    ol {
      @include fontsize(15 24);
    }
  }
}