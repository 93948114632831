/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1200px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1200px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.subpage main {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 0 20px 40px 20px;
  background: #eaebec;
}
@media (min-width: 640px) {
  .subpage main {
    flex-wrap: wrap;
    padding: 0 50px 50px 50px;
  }
}
.subpage .main-content {
  width: 100%;
}
@media (min-width: 640px) {
  .subpage .main-content {
    padding-top: 38px;
    width: 68%;
  }
}

.subpage.fullwidth main {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 0 20px 40px 20px;
  background: #eaebec;
}
@media (min-width: 640px) {
  .subpage.fullwidth main {
    flex-wrap: wrap;
    padding: 0 50px 50px 50px;
  }
}
.subpage.fullwidth .main-content {
  margin-top: -40px;
}
@media (min-width: 640px) {
  .subpage.fullwidth .main-content {
    margin-top: 0;
    padding-top: 0;
    width: 100%;
  }
}

.subpage aside {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 640px) {
  .subpage aside {
    padding: 50px 0 0 50px;
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .subpage aside {
    padding-left: 100px;
    padding-right: 0;
  }
}

.content-form {
  position: relative;
  overflow: hidden;
  margin-right: -1.6875rem;
  margin-left: -1.6875rem;
  /* recaptcha */
  /* small screens */
  /* medium screens */
  /* large screens */
}
.content-form h2,
.content-form .form-header,
.content-form .form-footer {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form h2 p:empty,
.content-form .form-header p:empty,
.content-form .form-footer p:empty {
  margin: 0;
}
.content-form .form-footer {
  margin-top: 1.875rem;
}
.content-form .validation-summary {
  display: none;
}
.content-form fieldset {
  border: 0;
  margin: 0 0 1.875rem 0;
  padding: 0;
}
.content-form fieldset:last-of-type {
  margin-bottom: 0;
}
.content-form label {
  position: relative;
  display: inline-block;
  clear: both;
}
.content-form input[type=text],
.content-form input[type=password],
.content-form input[type=date],
.content-form input[type=datetime],
.content-form input[type=datetime-local],
.content-form input[type=month],
.content-form input[type=week],
.content-form input[type=email],
.content-form input[type=number],
.content-form input[type=search],
.content-form input[type=tel],
.content-form input[type=time],
.content-form input[type=url],
.content-form textarea,
.content-form select {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 0 1.875rem 0;
  appearance: none;
}
.content-form input[type=text]:active, .content-form input[type=text]:focus,
.content-form input[type=password]:active,
.content-form input[type=password]:focus,
.content-form input[type=date]:active,
.content-form input[type=date]:focus,
.content-form input[type=datetime]:active,
.content-form input[type=datetime]:focus,
.content-form input[type=datetime-local]:active,
.content-form input[type=datetime-local]:focus,
.content-form input[type=month]:active,
.content-form input[type=month]:focus,
.content-form input[type=week]:active,
.content-form input[type=week]:focus,
.content-form input[type=email]:active,
.content-form input[type=email]:focus,
.content-form input[type=number]:active,
.content-form input[type=number]:focus,
.content-form input[type=search]:active,
.content-form input[type=search]:focus,
.content-form input[type=tel]:active,
.content-form input[type=tel]:focus,
.content-form input[type=time]:active,
.content-form input[type=time]:focus,
.content-form input[type=url]:active,
.content-form input[type=url]:focus,
.content-form textarea:active,
.content-form textarea:focus,
.content-form select:active,
.content-form select:focus {
  outline: 0;
}
.content-form textarea {
  height: auto;
  min-height: 7.5rem;
}
.content-form .error-msg {
  position: relative;
  top: -1.875rem;
  max-width: 100%;
  margin-top: 0;
  color: red;
}
.content-form .required label:after {
  position: relative;
  top: -7px;
  margin-left: 2px;
  content: "\f069";
  text-decoration: inherit;
  font-family: FontAwesome;
  font-size: 0.375rem;
  font-weight: normal;
  font-style: normal;
}
.content-form .checkbox {
  overflow: hidden;
}
.content-form input[type=submit] {
  margin-left: 1.6875rem;
  border: 0;
}
.content-form .g-recaptcha {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
  margin: 0 0 1.875rem 0;
}
.content-form .sm-full {
  float: left;
  clear: none;
  width: 100%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-half {
  float: left;
  clear: none;
  width: 50%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-third {
  float: left;
  clear: none;
  width: 33.333%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-two-thirds {
  float: left;
  clear: none;
  width: 66.666%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-quarter {
  float: left;
  clear: none;
  width: 25%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-three-quarters {
  float: left;
  clear: none;
  width: 75%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-last + div {
  clear: both;
}
@media screen and (min-width: 40rem) {
  .content-form .sm-last:not([class*=md-]) + div {
    clear: left;
  }
  .content-form .sm-last[class*=md-] {
    clear: none;
  }
  .content-form .md-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-third {
    float: left;
    clear: none;
    width: 33.333%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-two-thirds {
    float: left;
    clear: none;
    width: 66.666%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-three-quarters {
    float: left;
    clear: none;
    width: 75%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-last + div {
    clear: both;
  }
}
@media screen and (min-width: 64rem) {
  .content-form .sm-last:not([class*=md-]) + div {
    clear: left;
  }
  .content-form .sm-last[class*=md-] {
    clear: none;
  }
  .content-form .md-last:not([class*=lg-]) + div {
    clear: left;
  }
  .content-form .md-last[class*=lg-] {
    clear: none;
  }
  .content-form .lg-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-third {
    float: left;
    clear: none;
    width: 33.333%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-two-thirds {
    float: left;
    clear: none;
    width: 66.666%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-three-quarters {
    clear: none;
    width: 75%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-last + div {
    clear: both;
  }
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
}
.accordions .accordion-title button {
  position: relative;
  width: 100%;
  margin: 0 0 0.625rem 0;
  padding: 1.25rem 5.125rem 0.625rem 0;
  text-align: left;
  color: black;
  border: 0;
  border-bottom: 2px solid gray;
  background: transparent;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.accordions .accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 5rem;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions .accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 2rem;
  content: "\f067";
  transform: translateY(-8px);
  text-align: center;
  text-decoration: inherit;
  color: black;
  font-family: FontAwesome;
  font-size: 1.125rem;
  font-weight: normal;
  font-style: normal;
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:hover, .accordions .accordion-title button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title button:hover span, .accordions .accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button, .accordions .accordion-title.is-active button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title.is-active button span, .accordions .accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button span:after, .accordions .accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}
.tabs {
  position: relative;
  margin-bottom: 1.25rem;
}
.tabs.scroll-left:before, .tabs.scroll-right:after {
  position: absolute;
  z-index: 99;
  width: 2.5rem;
  height: 3.75rem;
  content: "";
}
.tabs.scroll-left:before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs.scroll-right:after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs .tab-con {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2px;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}
.tabs .tab-list[role=tablist] {
  display: inline-block;
}
.tabs .tab-list li {
  display: inline-block;
  margin: 0 3px 0 0;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list li:last-child {
  margin: 0;
}
.tabs .tab-list li a {
  position: relative;
  display: block;
  padding: 0.75rem 1.1875rem;
  background: gray;
  color: black;
  text-align: center;
  text-decoration: none;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a[role=tab]:focus {
  outline: 0;
  background: black;
  color: white;
}
.tabs .tab-list li a[aria-selected] {
  border: 0;
  background: gray;
  color: black;
  padding: 0.75rem 1.1875rem;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected][role=tab]:focus {
  background: gray;
  color: black;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

table.tablesaw {
  width: 100%;
  max-width: 100%;
  margin: 0 0 1.5rem 0;
  padding: 0;
  empty-cells: show;
  border: 0;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 17px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}

.tablesaw * {
  box-sizing: border-box;
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
  display: none;
}

@media only all {
  .tablesaw-stack {
    clear: both;
  }
  .tablesaw-stack td,
.tablesaw-stack th {
    display: block;
    text-align: left;
  }
  .tablesaw-stack tr {
    display: table-row;
    clear: both;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: inline-block;
    width: 30%;
    padding: 0 10px 0 0;
  }
  .tablesaw-stack th .tablesaw-cell-label-top,
.tablesaw-stack td .tablesaw-cell-label-top {
    display: block;
    margin: 6px 0;
    padding: 6px 0;
  }
  .tablesaw-stack .tablesaw-cell-label {
    display: block;
  }
  .tablesaw-stack tbody th.group {
    margin-top: -1px;
  }
  .tablesaw-stack th.group b.tablesaw-cell-label {
    display: none !important;
  }
}
@media (max-width: 39.9375em) {
  table.tablesaw-stack {
    margin: 0 -1.25rem 1.5rem -1.25rem;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
  }
  table.tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    padding: 0 1.25rem;
  }
  table.tablesaw-stack tbody tr:last-child {
    border-bottom: 1px solid gray;
  }
  table.tablesaw-stack thead {
    display: none;
  }
  table.tablesaw-stack tbody td,
table.tablesaw-stack tbody th {
    display: block;
    clear: left;
    width: 100%;
    padding: 0;
  }
  table.tablesaw-stack tbody td:last-child,
table.tablesaw-stack tbody th:last-child {
    border-bottom: 0;
  }
  table.tablesaw-stack td .tablesaw-cell-label {
    display: inline-block;
    padding: 0.5rem 0.5rem 0.5rem 0;
    text-transform: uppercase;
    vertical-align: top;
    background: transparent;
    font-weight: 700;
  }
  table.tablesaw-stack td .tablesaw-cell-content {
    display: inline-block;
    max-width: 67%;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
  table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-label,
table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-content {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  table.tablesaw-stack td:empty,
table.tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 40em) {
  .tablesaw-stack tr {
    display: table-row;
  }
  .tablesaw-stack td,
.tablesaw-stack th,
.tablesaw-stack thead td,
.tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
  .tablesaw-stack thead th {
    padding: 0.625rem 1.125rem 0.5rem;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
  }
  .tablesaw-stack thead th:last-child {
    border-right: 0;
  }
  .tablesaw-stack tbody tr td {
    padding: 0.75rem 1.125rem 0.625rem;
  }
  .tablesaw-stack tbody tr td:last-child {
    border-right: 0;
  }
}
.flex-container {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .flex-container.alignleft {
    width: 40%;
    float: left;
    margin: 0 15px 10px 0;
  }
  .flex-container.alignright {
    width: 40%;
    float: right;
    margin: 0 0 10px 15px;
  }
  .flex-container.aligncenter {
    width: 40%;
    margin: 0 auto 10px auto;
  }
}

.flex-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.search-results {
  font-size: 14px;
  line-height: 22px;
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: normal;
}
.search-results .stats {
  margin: 1.875rem 0;
}
.search-results .search-results-search {
  background: lightgray;
  overflow: hidden;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
}
.search-results .search-results-search input[name=search] {
  border: 0;
  padding: 0;
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 1.875rem;
  padding: 0 8px;
  width: calc(100% - 118px);
  font-style: italic;
  float: left;
}
.search-results .search-results-search input[name=search]::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]:-moz-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]::-moz-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]:-ms-input-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search button[name=search-submit] {
  display: inline-block;
  border: 0;
  padding: 0 8px;
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 1.875rem;
  width: 7.125rem;
  text-align: center;
  float: right;
  text-decoration: none;
}
.search-results .search-results-search button[name=search-submit]:hover, .search-results .search-results-search button[name=search-submit]:focus {
  text-decoration: none;
}
.search-results .results {
  position: relative;
  padding-top: 1.25rem;
}
.search-results .results:before {
  content: "";
  background: gray;
  position: absolute;
  top: -1rem;
  width: 100%;
  height: 1px;
}
.search-results .pagination {
  border-top: 1px solid gray;
}
.search-results .pagination > td {
  padding: 1rem 0 0 0;
}
.search-results .pagination span,
.search-results .pagination a {
  background: lightgray;
  color: gray;
  display: inline-block;
  text-decoration: none;
  padding: 3px 6px;
  border: 1px solid gray;
  font-size: 13px;
  line-height: 13px;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
}
.search-results .pagination a {
  color: blue;
}
.search-results .pagination a:visited {
  color: blue;
}
.search-results .pagination a:hover, .search-results .pagination a:focus {
  color: red;
}
.search-results th[scope=col] {
  display: none;
}
.search-results tr:nth-child(n+2):not(.pagination) > td {
  font-size: 16px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  padding-bottom: 1.25rem;
}
.search-results tr:nth-child(n+2):not(.pagination) > td a {
  display: block;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  letter-spacing: 0.0625em;
  font-weight: 400;
}

.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 640px) {
  .two-column,
.three-column,
.four-column,
.five-column,
.six-column {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.9375rem;
  }
}
.two-column .col,
.three-column .col,
.four-column .col,
.five-column .col,
.six-column .col {
  width: 100%;
}
.two-column li,
.three-column li,
.four-column li,
.five-column li,
.six-column li {
  margin: 0;
  padding: 0;
}
.two-column li:before,
.three-column li:before,
.four-column li:before,
.five-column li:before,
.six-column li:before {
  display: none;
}

@media (min-width: 640px) {
  .two-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}

@media (min-width: 640px) {
  .three-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .three-column .col {
    width: 33.3%;
  }
}

@media (min-width: 640px) {
  .four-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .four-column .col {
    width: 25%;
  }
}

@media (min-width: 640px) {
  .five-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .five-column .col {
    width: 20%;
  }
}

@media (min-width: 640px) {
  .six-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .six-column .col {
    width: 16.6666666667%;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 640px) {
  img {
    max-width: 100%;
  }
}
img[class*=left] {
  margin: 0 0 0.9375rem 0;
}
@media (min-width: 640px) {
  img[class*=left] {
    float: left;
    max-width: 40%;
    margin: 0.75rem 3.125rem 1.25rem 0;
  }
}
img[class*=right] {
  margin: 0 0 0.9375rem 0;
}
@media (min-width: 640px) {
  img[class*=right] {
    float: right;
    max-width: 40%;
    margin: 0.75rem 0 1.25rem 3.125rem;
  }
}
img[class*=center] {
  margin: 0 auto 0.9375rem auto;
}
@media (min-width: 640px) {
  img[class*=center] {
    margin: 0 auto 1.25rem auto;
  }
}

figure {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 640px) {
  figure {
    max-width: 100%;
  }
}
figure img {
  display: block;
  margin: 0 auto;
}
figure img[class*=align] {
  margin: 0 auto;
  float: none;
}
@media (min-width: 640px) {
  figure img[class*=align] {
    margin: 0 auto;
    float: none;
  }
}
figure[class*=left] {
  margin: 0 0 0.9375rem 0;
}
@media (min-width: 640px) {
  figure[class*=left] {
    float: left;
    max-width: 40%;
    margin: 0.75rem 3.125rem 1.25rem 0;
  }
}
figure[class*=right] {
  margin: 0 0 0.9375rem 0;
}
@media (min-width: 640px) {
  figure[class*=right] {
    float: right;
    max-width: 40%;
    margin: 0.75rem 0 1.25rem 3.125rem;
  }
}
figure[class*=center] {
  margin: 0 auto 0.9375rem auto;
}
@media (min-width: 640px) {
  figure[class*=center] {
    margin: 0 auto 1.25rem auto;
  }
}
figure figcaption {
  margin-top: 1px;
  font-style: italic;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: white;
  background: #323e48;
  padding: 10px 25px;
}

.two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 640px) {
  .two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5625rem;
  }
}
.two-column .col,
.two-column li.col,
.three-column .col,
.three-column li.col,
.four-column .col,
.four-column li.col,
.five-column .col,
.five-column li.col,
.six-column .col,
.six-column li.col,
ul.two-column .col,
ul.two-column li.col,
ul.three-column .col,
ul.three-column li.col,
ul.four-column .col,
ul.four-column li.col,
ul.five-column .col,
ul.five-column li.col,
ul.six-column .col,
ul.six-column li.col {
  width: 100%;
}
.two-column li.col,
.three-column li.col,
.four-column li.col,
.five-column li.col,
.six-column li.col,
ul.two-column li.col,
ul.three-column li.col,
ul.four-column li.col,
ul.five-column li.col,
ul.six-column li.col {
  margin: 0;
  padding: 0;
}
.two-column li.col:before,
.three-column li.col:before,
.four-column li.col:before,
.five-column li.col:before,
.six-column li.col:before,
ul.two-column li.col:before,
ul.three-column li.col:before,
ul.four-column li.col:before,
ul.five-column li.col:before,
ul.six-column li.col:before {
  display: none;
}

@media (min-width: 640px) {
  .two-column .col,
ul.two-column li.col {
    width: 50%;
    padding: 0 0.9375rem 1.875rem 0.9375rem;
  }
}

@media (min-width: 640px) {
  .three-column .col,
ul.three-column li.col {
    width: 50%;
    padding: 0 0.9375rem 1.875rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .three-column .col,
ul.three-column li.col {
    width: 33.3%;
  }
}

@media (min-width: 640px) {
  .four-column .col,
ul.four-column li.col {
    width: 50%;
    padding: 0 0.9375rem 1.875rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .four-column .col,
ul.four-column li.col {
    width: 25%;
  }
}

@media (min-width: 640px) {
  .five-column .col,
ul.five-column li.col {
    width: 50%;
    padding: 0 0.9375rem 1.875rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .five-column .col,
ul.five-column li.col {
    width: 20%;
  }
}

@media (min-width: 640px) {
  .six-column .col,
ul.six-column li.col {
    width: 50%;
    padding: 0 0.9375rem 1.875rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .six-column .col,
ul.six-column li.col {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .six-column .col,
ul.six-column li.col {
    width: 16.6666666667%;
  }
}

table:not(.results) table,
table.tablesaw {
  margin: 0 0 40px 0;
  width: 100%;
  max-width: 100%;
  font-size: 15px;
  line-height: 20px;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: normal;
}
table:not(.results) table thead tr,
table.tablesaw thead tr {
  background: #323e48;
}
table:not(.results) table tbody tr:nth-child(odd),
table.tablesaw tbody tr:nth-child(odd) {
  background: rgba(255, 255, 255, 0.5);
}
table:not(.results) table tbody tr:nth-child(even),
table.tablesaw tbody tr:nth-child(even) {
  background: #dddfe0;
}
table:not(.results) table th,
table:not(.results) table td,
table.tablesaw th,
table.tablesaw td {
  padding: 15px 25px;
  border-right: 2px solid #eaebec;
}

@media (max-width: 39.9375em) {
  table.tablesaw-stack {
    margin-bottom: 22px;
    border-top: 5px solid #323e48;
    border-bottom: 5px solid #323e48;
  }
  table.tablesaw-stack tbody tr {
    padding: 20px;
  }
  table.tablesaw-stack td {
    margin-bottom: 10px;
  }
  table.tablesaw-stack td .tablesaw-cell-label {
    display: block;
    width: 100%;
    padding: 0;
  }
  table.tablesaw-stack td .tablesaw-cell-content {
    display: block;
    padding: 0;
    max-width: 100%;
  }
  table.tablesaw-stack th,
table.tablesaw-stack td {
    padding: 0;
    border-right: 0;
  }
}
.tabs {
  margin-bottom: 0;
}
.tabs.scroll-right:after {
  height: 35px;
  background: linear-gradient(to left, #eaebec 0%, rgba(234, 235, 236, 0) 100%);
}
@media (min-width: 640px) {
  .tabs.scroll-right:after {
    height: 47px;
  }
}
.tabs.scroll-left:before {
  height: 35px;
  background: linear-gradient(to left, #eaebec 0%, rgba(234, 235, 236, 0) 100%);
}
@media (min-width: 640px) {
  .tabs.scroll-left:before {
    height: 47px;
  }
}
.tabs .tab-list {
  font-size: 15px;
  line-height: 15px;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  letter-spacing: normal;
}
.tabs .tab-list li {
  margin-right: 2px;
  border-left: 0;
}
.tabs .tab-list li a {
  padding: 10px 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-transform: uppercase;
  color: white;
  background: #bf332c;
}
@media (min-width: 640px) {
  .tabs .tab-list li a {
    padding: 16px 25px;
  }
}
.tabs .tab-list li a:visited {
  color: white;
}
.tabs .tab-list li a[aria-selected] {
  padding: 10px 16px;
  color: white;
  background: #323e48;
}
@media (min-width: 640px) {
  .tabs .tab-list li a[aria-selected] {
    padding: 16px 25px;
  }
}
.tabs .tab-list li a[aria-selected]:hover {
  color: white;
  background: #323e48;
}
.tabs .tab-list li a:hover {
  color: white;
  background: #003da7;
}
.tabs .tab-list li a[role=tab]:focus {
  color: white;
  background: #323e48;
}
.tabs .tab-list li a[aria-selected][role=tab]:focus {
  color: white;
  background: #323e48;
}
.tabs .tab-content {
  margin-bottom: 20px;
  padding: 20px;
  border-top: 5px solid #323e48;
  font-size: 15px;
  line-height: 24px;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  background: #dddfe0;
}
@media (min-width: 640px) {
  .tabs .tab-content {
    padding: 25px;
  }
}
.tabs .tab-content p,
.tabs .tab-content ul,
.tabs .tab-content ol {
  font-size: 15px;
  line-height: 24px;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
}
.accordions .accordion-title button {
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
  padding: 16px 25px 16px 73px;
  font-family: "Roboto", sans-serif;
  text-align: left;
  color: white;
  border-bottom: 0;
  background: #323e48;
  font-size: 15px;
  line-height: 15px;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  letter-spacing: normal;
  text-transform: uppercase;
}
.accordions .accordion-title button span {
  position: absolute;
  left: 0;
  right: auto;
  bottom: 0;
  width: 50px;
  height: 100%;
  border-bottom: 0;
  border-right: 2px solid #eaebec;
  background: #bf332c;
}
.accordions .accordion-title button span:after {
  position: absolute;
  top: calc(50% + 3px);
  left: 17px;
  content: "\f067";
  transform: translateY(-8px);
  text-align: center;
  text-decoration: inherit;
  color: white;
  font-family: FontAwesome;
  font-size: 1.125rem;
  font-weight: normal;
  font-style: normal;
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:focus {
  color: white;
  border: 0;
  outline: 0;
  background: #323e48;
}
.accordions .accordion-title button:focus span {
  background: #bf332c;
  border-bottom: 0;
  border-right: 2px solid #eaebec;
}
.accordions .accordion-title button:hover {
  color: white;
  border: 0;
  outline: 0;
  background: #003da7;
}
.accordions .accordion-title button:hover span {
  background: #003da7;
  border-bottom: 0;
  border-right: 2px solid #eaebec;
}
.accordions .accordion-title.is-active button, .accordions .accordion-title.is-active button:focus {
  margin-bottom: 2px;
  color: white;
  border: 0;
  outline: 0;
  background: #323e48;
}
.accordions .accordion-title.is-active button span, .accordions .accordion-title.is-active button:focus span {
  color: white;
  border-bottom: 0;
  border-right: 2px solid #eaebec;
  background: #bf332c;
}
.accordions .accordion-title.is-active button span:after, .accordions .accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions .accordion-title.is-active button:hover {
  margin-bottom: 2px;
  color: white;
  border: 0;
  outline: 0;
  background: #003da7;
}
.accordions .accordion-title.is-active button:hover span {
  color: white;
  border-bottom: 0;
  border-right: 2px solid #eaebec;
  background: #003da7;
}
.accordions .accordion-title.is-active button:hover span:after {
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}
.accordions.active .accordion-content {
  margin-bottom: 20px;
  padding: 20px;
  border-top: 5px solid #323e48;
  background: #dddfe0;
}
.accordions.active .accordion-content p,
.accordions.active .accordion-content ul,
.accordions.active .accordion-content ol {
  font-size: 15px;
  line-height: 24px;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}

.content-form {
  margin-right: -25px;
  margin-left: -25px;
  /* small screens */
  /* medium screens */
  /* large screens */
}
.content-form h2,
.content-form .form-header,
.content-form .form-footer {
  padding-right: 25px;
  padding-left: 25px;
}
.content-form label {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  text-transform: uppercase;
  font-weight: 700;
}
.content-form input[type=text],
.content-form input[type=password],
.content-form input[type=date],
.content-form input[type=datetime],
.content-form input[type=datetime-local],
.content-form input[type=month],
.content-form input[type=week],
.content-form input[type=email],
.content-form input[type=number],
.content-form input[type=search],
.content-form input[type=tel],
.content-form input[type=time],
.content-form input[type=url],
.content-form textarea,
.content-form select {
  box-shadow: none;
  appearance: none;
  padding: 0 20px;
  height: 48px;
  margin: 0 0 30px 0;
  font-family: "Roboto", sans-serif;
  background: white;
  font-size: 18px;
  line-height: 48px;
  font-size: 1.125rem;
  line-height: 3rem;
  letter-spacing: normal;
  border: 2px solid #dddfe0;
  color: #323e48;
}
.content-form input[type=text]:active, .content-form input[type=text]:focus,
.content-form input[type=password]:active,
.content-form input[type=password]:focus,
.content-form input[type=date]:active,
.content-form input[type=date]:focus,
.content-form input[type=datetime]:active,
.content-form input[type=datetime]:focus,
.content-form input[type=datetime-local]:active,
.content-form input[type=datetime-local]:focus,
.content-form input[type=month]:active,
.content-form input[type=month]:focus,
.content-form input[type=week]:active,
.content-form input[type=week]:focus,
.content-form input[type=email]:active,
.content-form input[type=email]:focus,
.content-form input[type=number]:active,
.content-form input[type=number]:focus,
.content-form input[type=search]:active,
.content-form input[type=search]:focus,
.content-form input[type=tel]:active,
.content-form input[type=tel]:focus,
.content-form input[type=time]:active,
.content-form input[type=time]:focus,
.content-form input[type=url]:active,
.content-form input[type=url]:focus,
.content-form textarea:active,
.content-form textarea:focus,
.content-form select:active,
.content-form select:focus {
  border-color: #1884ec;
  outline: 0;
  box-shadow: none;
}
.content-form input[type=text]::-webkit-input-placeholder,
.content-form input[type=password]::-webkit-input-placeholder,
.content-form input[type=date]::-webkit-input-placeholder,
.content-form input[type=datetime]::-webkit-input-placeholder,
.content-form input[type=datetime-local]::-webkit-input-placeholder,
.content-form input[type=month]::-webkit-input-placeholder,
.content-form input[type=week]::-webkit-input-placeholder,
.content-form input[type=email]::-webkit-input-placeholder,
.content-form input[type=number]::-webkit-input-placeholder,
.content-form input[type=search]::-webkit-input-placeholder,
.content-form input[type=tel]::-webkit-input-placeholder,
.content-form input[type=time]::-webkit-input-placeholder,
.content-form input[type=url]::-webkit-input-placeholder,
.content-form textarea::-webkit-input-placeholder,
.content-form select::-webkit-input-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 48px;
  font-size: 1.125rem;
  line-height: 3rem;
  letter-spacing: normal;
}
.content-form input[type=text]:-moz-placeholder,
.content-form input[type=password]:-moz-placeholder,
.content-form input[type=date]:-moz-placeholder,
.content-form input[type=datetime]:-moz-placeholder,
.content-form input[type=datetime-local]:-moz-placeholder,
.content-form input[type=month]:-moz-placeholder,
.content-form input[type=week]:-moz-placeholder,
.content-form input[type=email]:-moz-placeholder,
.content-form input[type=number]:-moz-placeholder,
.content-form input[type=search]:-moz-placeholder,
.content-form input[type=tel]:-moz-placeholder,
.content-form input[type=time]:-moz-placeholder,
.content-form input[type=url]:-moz-placeholder,
.content-form textarea:-moz-placeholder,
.content-form select:-moz-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 48px;
  font-size: 1.125rem;
  line-height: 3rem;
  letter-spacing: normal;
}
.content-form input[type=text]::-moz-placeholder,
.content-form input[type=password]::-moz-placeholder,
.content-form input[type=date]::-moz-placeholder,
.content-form input[type=datetime]::-moz-placeholder,
.content-form input[type=datetime-local]::-moz-placeholder,
.content-form input[type=month]::-moz-placeholder,
.content-form input[type=week]::-moz-placeholder,
.content-form input[type=email]::-moz-placeholder,
.content-form input[type=number]::-moz-placeholder,
.content-form input[type=search]::-moz-placeholder,
.content-form input[type=tel]::-moz-placeholder,
.content-form input[type=time]::-moz-placeholder,
.content-form input[type=url]::-moz-placeholder,
.content-form textarea::-moz-placeholder,
.content-form select::-moz-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 48px;
  font-size: 1.125rem;
  line-height: 3rem;
  letter-spacing: normal;
}
.content-form input[type=text]:-ms-input-placeholder,
.content-form input[type=password]:-ms-input-placeholder,
.content-form input[type=date]:-ms-input-placeholder,
.content-form input[type=datetime]:-ms-input-placeholder,
.content-form input[type=datetime-local]:-ms-input-placeholder,
.content-form input[type=month]:-ms-input-placeholder,
.content-form input[type=week]:-ms-input-placeholder,
.content-form input[type=email]:-ms-input-placeholder,
.content-form input[type=number]:-ms-input-placeholder,
.content-form input[type=search]:-ms-input-placeholder,
.content-form input[type=tel]:-ms-input-placeholder,
.content-form input[type=time]:-ms-input-placeholder,
.content-form input[type=url]:-ms-input-placeholder,
.content-form textarea:-ms-input-placeholder,
.content-form select:-ms-input-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 48px;
  font-size: 1.125rem;
  line-height: 3rem;
  letter-spacing: normal;
}
.content-form textarea {
  height: auto;
  min-height: 120px;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}
.content-form textarea::-webkit-input-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}
.content-form textarea:-moz-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}
.content-form textarea::-moz-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}
.content-form textarea:-ms-input-placeholder {
  color: #323e48;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}
.content-form select {
  padding-right: 40px;
  position: relative;
  background-image: url("../images/bg-select.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 40px 96px;
}
.content-form select.open {
  background-position: bottom right;
}
.content-form select::-ms-expand {
  display: none;
}
.content-form input[type=checkbox],
.content-form input[type=radio] {
  position: relative;
  float: left;
  width: 20px;
  height: 20px;
  max-height: auto;
  margin: 0 0 30px 0;
  border: 2px solid #dddfe0;
  background: white;
  appearance: none;
}
.content-form input[type=checkbox]:checked,
.content-form input[type=radio]:checked {
  border-color: #1884ec;
  background: white;
  appearance: none;
}
.content-form input[type=checkbox]:checked:before,
.content-form input[type=radio]:checked:before {
  position: absolute;
  top: -1px;
  left: 0px;
  content: "\f00c";
  text-decoration: inherit;
  color: #1884ec;
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
}
.content-form input[type=checkbox]:focus,
.content-form input[type=radio]:focus {
  border-color: #1884ec;
  outline: none;
  appearance: none;
}
.content-form input[type=checkbox] + label,
.content-form input[type=radio] + label {
  position: relative;
  top: 0;
  display: inline-block;
  width: calc(100% - 20px);
  margin: 0 0 30px 0;
  padding-left: 10px;
}
.content-form input[type=radio] {
  border-radius: 50%;
  margin-bottom: 0;
}
.content-form input[type=radio] + label {
  top: -5px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
}
.content-form .radio-list ul {
  padding-left: 0;
}
.content-form .radio-list ul li {
  margin-bottom: 10px;
  padding-left: 0;
  border-left: 0;
}
.content-form .error-msg {
  position: relative;
  top: -25px;
  max-width: 100%;
  margin-top: 0;
  color: red;
  font-size: 13px;
  line-height: 15px;
  font-size: 0.8125rem;
  line-height: 0.9375rem;
  letter-spacing: normal;
  font-style: italic;
}
.content-form .required label:after {
  position: relative;
  top: -7px;
  margin-left: 2px;
  content: "\f069";
  text-decoration: inherit;
  font-family: FontAwesome;
  font-size: 6px;
  font-weight: normal;
  font-style: normal;
}
.content-form input[type=submit] {
  cursor: pointer;
  margin: 0;
  background: #bf332c;
  color: white;
  font-size: 18px;
  line-height: 18px;
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  padding: 18px 44px;
  border: 0;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  height: auto;
  text-align: center;
}
.content-form input[type=submit]:hover, .content-form input[type=submit]:focus {
  border: 0;
  color: white;
  background: #003da7;
}
.content-form input[type=submit] {
  margin-left: 25px;
}
.content-form .g-recaptcha {
  padding-right: 25px;
  padding-left: 25px;
  margin: 0 0 30px 0;
}
.content-form .sm-full {
  padding-right: 25px;
  padding-left: 25px;
}
.content-form .sm-half {
  padding-right: 25px;
  padding-left: 25px;
}
.content-form .sm-third {
  padding-right: 25px;
  padding-left: 25px;
}
.content-form .sm-two-thirds {
  padding-right: 25px;
  padding-left: 25px;
}
.content-form .sm-quarter {
  padding-right: 25px;
  padding-left: 25px;
}
.content-form .sm-three-quarters {
  padding-right: 25px;
  padding-left: 25px;
}
@media screen and (min-width: 40rem) {
  .content-form .md-full {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .md-half {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .md-third {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .md-two-thirds {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .md-quarter {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .md-three-quarters {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (min-width: 64rem) {
  .content-form .lg-full {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .lg-half {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .lg-third {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .lg-two-thirds {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .lg-quarter {
    padding-right: 25px;
    padding-left: 25px;
  }
  .content-form .lg-three-quarters {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.nav-secondary h2 {
  margin: 0;
  padding: 12px 0 12px 20px;
  font-size: 18px;
  line-height: 20px;
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: normal;
  color: #323e48;
  font-weight: 700;
  letter-spacing: 0.03125em;
  text-transform: uppercase;
  border-left: 5px solid #323e48;
}
.nav-secondary ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  line-height: 20px;
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: normal;
}
.nav-secondary ul li {
  margin: 0;
  padding: 0;
}
.nav-secondary ul li a {
  display: block;
  padding: 12px 0 12px 20px;
  border-left: 5px solid #cccccc;
}

.btn-cta {
  display: block;
  position: relative;
  margin: 10px 0;
  padding: 12px 0 12px 20px;
  font-size: 18px;
  line-height: 20px;
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: normal;
  font-weight: 700;
  letter-spacing: 0.03125em;
  text-transform: uppercase;
  color: #bf332c;
  border-left: 5px solid #bf332c;
}
.btn-cta:after {
  position: relative;
  margin-left: 5px;
  content: "\f18e";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
.btn-cta:visited {
  color: #bf332c;
}
.btn-cta:active {
  text-decoration: none;
  color: #003da7;
  border-color: #003da7;
}
.btn-cta:hover {
  color: #003da7;
  text-decoration: none;
  border-color: #003da7;
}

.search-results .search-results-search {
  background: #dddfe0;
  overflow: hidden;
}
.search-results .search-results-search [type=search] {
  float: left;
  padding: 0 5px;
  width: calc(100% - 122px);
  height: 31px;
  border: 0;
  color: #323e48;
  font-family: "Roboto", sans-serif;
  background: white;
  appearance: none;
  font-size: 16px;
  line-height: 16px;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: normal;
}
.search-results .search-results-search [type=submit] {
  display: inline-block;
  float: right;
  cursor: pointer;
  margin: 0;
  width: 112px;
  height: 31px;
  background: #bf332c;
  color: white;
  font-size: 15px;
  line-height: 15px;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  letter-spacing: normal;
  padding: 8px 24px;
  border: 0;
  font-weight: 700;
  text-transform: uppercase;
  height: auto;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.search-results .search-results-search [type=submit]:hover, .search-results .search-results-search [type=submit]:focus {
  border: 0;
  color: white;
  background: #003da7;
}
.search-results .results:before {
  background: #adb0b3;
}
.search-results .results tbody tr:nth-child(odd) {
  background: rgba(255, 255, 255, 0.5);
}
.search-results .results tbody tr:nth-child(even) {
  background: #dddfe0;
}
.search-results .results tbody tr.pagination {
  background: transparent;
}
.search-results .results tbody tr:nth-child(n+2):not(.pagination) > td {
  padding: 15px;
}
.search-results .results tbody tr:nth-child(n+2):not(.pagination) > td a {
  margin-bottom: 5px;
  font-weight: 700;
}
.search-results .results tbody tr:nth-child(n+2):not(.pagination) > td a mark {
  background: rgba(0, 0, 0, 0.1);
}
.search-results .results .pagination {
  border-color: #adb0b3;
}
.search-results .results .pagination table tbody tr {
  background: transparent;
}
.search-results .results .pagination span,
.search-results .results .pagination a {
  color: white;
  background: #003da7;
  display: inline-block;
  text-decoration: none;
  padding: 8px 12px;
  border: 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
}
.search-results .results .pagination a {
  background: #323e48;
  color: white;
}
.search-results .results .pagination a:visited {
  background: #323e48;
  color: white;
}
.search-results .results .pagination a:hover, .search-results .results .pagination a:focus {
  color: white;
  background: #bf332c;
}

.team-card {
  background: #323f48;
  margin-bottom: 30px;
}
@media (min-width: 640px) {
  .team-card {
    height: 100%;
  }
}
.team-card h2,
.team-card span {
  color: #fff;
}
.team-card h2 {
  font-family: "Rokkitt", serif;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: none;
}
.team-card__content {
  border-top: 1px solid #fff;
  padding: 25px;
}
.team-card__position {
  display: block;
  margin-bottom: 12px;
  font-size: 18px;
}
.team-card__location {
  display: inline-block;
  text-transform: uppercase;
  background: #000;
  padding: 8px 10px 7px;
  border-radius: 7px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.album > p {
  display: none;
}

.subpage.fullwidth.locations-page main {
  padding-bottom: 0;
}

.locations {
  margin-left: -20px;
  margin-right: -20px;
}
@media (min-width: 640px) {
  .locations {
    margin-left: -50px;
    margin-right: -50px;
  }
}
.locations .map {
  border-bottom: 5px solid white;
}
.locations .map .scrollFix h2 {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  font-weight: 600;
}
.locations .map .scrollFix p {
  font-size: 16px;
  line-height: 19px;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: normal;
}
.locations .location-selector {
  display: none;
}
.locations section {
  display: flex;
  flex-wrap: wrap;
}
.locations .location {
  padding: 0 20px 40px 20px;
  border-bottom: 5px solid white;
  width: 100%;
}
@media (min-width: 640px) {
  .locations .location {
    overflow: hidden;
    padding: 0;
    border-width: 2px;
    width: 100%;
  }
  .locations .location .info {
    padding: 50px;
    width: 50%;
    float: right;
  }
}
.locations .location .image {
  position: relative;
  border-bottom: 5px solid #bf332c;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 40px;
}
@media (min-width: 640px) {
  .locations .location .image {
    margin: 0;
    float: left;
    width: 50%;
    border-bottom: 0;
    border-right: 5px solid #bf332c;
    overflow: hidden;
  }
  .locations .location .image img {
    min-width: 100%;
  }
}
.locations .location .image:after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: white;
  content: "";
}
@media (min-width: 640px) {
  .locations .location .image:after {
    bottom: auto;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
  }
}
@media (min-width: 640px) {
  .locations .location ~ .location {
    display: inline-block;
    width: 33.3333333333%;
    border-right: 2px solid white;
    border-bottom: 0;
  }
  .locations .location ~ .location:last-child {
    border-right: 0;
  }
  .locations .location ~ .location .info {
    float: none;
    width: 100%;
  }
}
.locations .location ~ .location .image {
  border-color: #323e48;
}
@media (min-width: 640px) {
  .locations .location ~ .location .image {
    float: none;
    width: 100%;
    border-right: 0;
    border-bottom: 5px solid #323e48;
  }
  .locations .location ~ .location .image:after {
    width: 100%;
    height: 2px;
    bottom: 0;
    top: auto;
    right: auto;
  }
}
.locations .location [itemprop=addressLocality] {
  display: block;
  font-size: 18px;
  line-height: 20px;
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.locations .location [property="schema:streetAddress"] {
  display: block;
}
.locations .location [property="schema:addressLocality"] {
  clear: left;
}
.locations .location [itemprop=telephone] {
  display: block;
}
.locations .location [itemprop=telephone] strong {
  color: #003da7;
}
.locations .content-form {
  margin: 0;
}
@media (min-width: 640px) {
  .locations .content-form {
    border-top: 5px solid white;
    padding-left: 20px;
    padding-right: 20px;
  }
}