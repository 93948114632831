.team-card {
  background: #323f48;
  margin-bottom: 30px;

  @include medium-up {
    height: 100%;
  }

  h2,
  span {
    color: #fff;
  }

  h2 {
    font-family: "Rokkitt", serif;
    font-weight: 500;
    margin-bottom: 5px;
    text-transform: none;
  }

  &__content {
    border-top: 1px solid #fff;
    padding: 25px;
  }

  &__position {
    display: block;
    margin-bottom: 12px;
    font-size: 18px;
  }

  &__location {
    display: inline-block;
    text-transform: uppercase;
    background: #000;
    padding: 8px 10px 7px;
    border-radius: 7px;
    @include franklin-con;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}