.nav-secondary {
  h2 {
    margin: 0;
    padding: 12px 0 12px 20px;
    @include fontsize(18 20);
    color: $limedspruce;
    font-weight: 700;
    letter-spacing: 0.03125em;
    text-transform: uppercase;
    border-left: 5px solid $limedspruce;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include fontsize(18 20);

    li {
      margin: 0;
      padding: 0;

      a {
        display: block;
        padding: 12px 0 12px 20px;
        border-left: 5px solid $silver;
      }
    }
  }
}

.btn-cta {
  display: block;
  position: relative;
  margin: 10px 0;
  padding: 12px 0 12px 20px;
  @include fontsize(18 20);
  font-weight: 700;
  letter-spacing: 0.03125em;
  text-transform: uppercase;
  color: $tallpoppy;
  border-left: 5px solid $tallpoppy;

  &:after {
    position: relative;
    margin-left: 5px;
    content: '\f18e';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
  }

  &:visited {
    color: $tallpoppy;
  }

  &:active {
    text-decoration: none;
    color: $cobalt;
    border-color: $cobalt;
  }

  &:hover {
    color: $cobalt;
    text-decoration: none;
    border-color: $cobalt;
  }
}