.content-form {
  margin-right: -25px;
  margin-left: -25px;

  h2,
  .form-header,
  .form-footer {
    padding-right: 25px;
    padding-left: 25px;
  }

  label {
    margin-bottom: 5px;
    @include fontsize(18 21);
    text-transform: uppercase;
    font-weight: 700;
  }

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  textarea,
  select {
    box-shadow: none;
    appearance: none;
    padding: 0 20px;
    height: 48px;
    margin: 0 0 30px 0;
    font-family: $franklin;
    background: white;
    @include fontsize(18 48);
    border: 2px solid $lightiron;
    color: $limedspruce;

    &:active,
    &:focus {
      border-color: $dodgerblue;
      outline: 0;
      box-shadow: none;
    }
    @include placeholder {
      color: $limedspruce;
      @include fontsize(18 48);
    }
  }

  textarea {
    height: auto;
    min-height: 120px;
    @include fontsize(18 21);
    @include placeholder {
      color: $limedspruce;
      @include fontsize(18 21);
    }
  }

  select {
    padding-right: 40px;
    position: relative;
    background-image: url('../images/bg-select.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40px 96px;

    &.open {
      background-position: bottom right;
    }
  }

  select::-ms-expand {
    display: none;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    max-height: auto;
    margin: 0 0 30px 0;
    border: 2px solid $lightiron;
    background: white;
    appearance: none;

    &:checked {
      border-color: $dodgerblue;
      background: white;
      appearance: none;

      &:before {
        position: absolute;
        top: -1px;
        left: 0px;
        content: '\f00c';
        text-decoration: inherit;
        color: $dodgerblue;
        font-family: FontAwesome;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
      }
    }

    &:focus {
      border-color: $dodgerblue;
      outline: none;
      appearance: none;
    }

    + label {
      position: relative;
      top: 0;
      display: inline-block;
      width: calc(100% - 20px);
      margin: 0 0 30px 0;
      padding-left: 10px;
    }
  }

  input[type='radio'] {
    border-radius: 50%;
    margin-bottom: 0;

    + label {
      top: -5px;
      margin-bottom: 0;
      @include fontsize(18 21);
      font-weight: 400;
      text-transform: none;
    }
  }

  .radio-list ul {
    padding-left: 0;

    li {
      margin-bottom: 10px;
      padding-left: 0;
      border-left: 0;

      &:last-child {
      }
    }
  }

  .error-msg {
    position: relative;
    top: -25px;
    max-width: 100%;
    margin-top: 0;
    color: red;
    @include fontsize(13 15);
    font-style: italic;
  }

  .required {
    label:after {
      position: relative;
      top: -7px;
      margin-left: 2px;
      content: '\f069';
      text-decoration: inherit;
      font-family: FontAwesome;
      font-size: 6px;
      font-weight: normal;
      font-style: normal;
    }
  }

  input[type='submit'] {
    cursor: pointer;
    margin: 0;
    background: $tallpoppy;
    color: white;
    @include fontsize(18 18);
    padding: 18px 44px;
    border: 0;
    font-weight: 700;
    font-family: $franklin;
    text-transform: uppercase;
    height: auto;
    text-align: center;

    &:hover,
    &:focus {
      border: 0;
      color: white;
      background: $cobalt;
    }
  }

  input[type='submit'] {
    margin-left: 25px;
  }

  .g-recaptcha {
    padding-right: 25px;
    padding-left: 25px;
    margin: 0 0 30px 0;
  }
  /* small screens */
  .sm-full {
    padding-right: 25px;
    padding-left: 25px;
  }

  .sm-half {
    padding-right: 25px;
    padding-left: 25px;
  }

  .sm-third {
    padding-right: 25px;
    padding-left: 25px;
  }

  .sm-two-thirds {
    padding-right: 25px;
    padding-left: 25px;
  }

  .sm-quarter {
    padding-right: 25px;
    padding-left: 25px;
  }

  .sm-three-quarters {
    padding-right: 25px;
    padding-left: 25px;
  }


  /* medium screens */
  @media screen and (min-width: 40rem) {
    .md-full {
      padding-right: 25px;
      padding-left: 25px;
    }

    .md-half {
      padding-right: 25px;
      padding-left: 25px;
    }

    .md-third {
      padding-right: 25px;
      padding-left: 25px;
    }

    .md-two-thirds {
      padding-right: 25px;
      padding-left: 25px;
    }

    .md-quarter {
      padding-right: 25px;
      padding-left: 25px;
    }

    .md-three-quarters {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
  /* large screens */
  @media screen and (min-width: 64rem) {
    .lg-full {
      padding-right: 25px;
      padding-left: 25px;
    }

    .lg-half {
      padding-right: 25px;
      padding-left: 25px;
    }

    .lg-third {
      padding-right: 25px;
      padding-left: 25px;
    }

    .lg-two-thirds {
      padding-right: 25px;
      padding-left: 25px;
    }

    .lg-quarter {
      padding-right: 25px;
      padding-left: 25px;
    }

    .lg-three-quarters {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
}
