.accordions {
  margin-bottom: 20px;

  .accordion-title {
    position: relative;

    button {
      position: relative;
      width: 100%;
      margin: 0 0 30px 0;
      padding: 16px 25px 16px 73px;
      font-family: $franklin;
      text-align: left;
      color: white;
      border-bottom: 0;
      background: $limedspruce;
      @include fontsize(15 15);
      text-transform: uppercase;

      span {
        position: absolute;
        left: 0;
        right: auto;
        bottom: 0;
        width: 50px;
        height: 100%;
        border-bottom: 0;
        border-right: 2px solid $athensgray;
        background: $tallpoppy;

        &:after {
          position: absolute;
          top: calc(50% + 3px);
          left: 17px;
          content: '\f067';
          transform: translateY(-8px);
          text-align: center;
          text-decoration: inherit;
          color: white;
          font-family: FontAwesome;
          font-size: rem-calc(18px);
          font-weight: normal;
          font-style: normal;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        color: white;
        border: 0;
        outline: 0;
        background: $limedspruce;

        span {
          background: $tallpoppy;
          border-bottom: 0;
          border-right: 2px solid $athensgray;
        }
      }

      &:hover {
        color: white;
        border: 0;
        outline: 0;
        background: $cobalt;

        span {
          background: $cobalt;
          border-bottom: 0;
          border-right: 2px solid $athensgray;
        }
      }
    }

    &.is-active button,
    &.is-active button:focus {
      margin-bottom: 2px;
      color: white;
      border: 0;
      outline: 0;
      background: $limedspruce;

      span {
        color: white;
        border-bottom: 0;
        border-right: 2px solid $athensgray;
        background: $tallpoppy;

        &:after {
          content: '\f068';
        }
      }
    }

    &.is-active button:hover {
      margin-bottom: 2px;
      color: white;
      border: 0;
      outline: 0;
      background: $cobalt;

      span {
        color: white;
        border-bottom: 0;
        border-right: 2px solid $athensgray;
        background: $cobalt;

        &:after {
          content: '\f068';
        }
      }
    }
  }

  .accordion-content[aria-hidden] {
    display: none;
  }

  .accordion-content[aria-hidden='false'] {
    display: block;
  }

  &.active {
    h2 {
      margin: 0;
    }

    .accordion-content {
      margin-bottom: 20px;
      padding: 20px;
      border-top: 5px solid $limedspruce;
      background: $lightiron;

      p,
      ul,
      ol {
        @include fontsize(15 24);
      }
    }
  }
}