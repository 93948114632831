.subpage.fullwidth.locations-page {
  main {
    padding-bottom: 0;
  }
}

.locations {
  margin-left: -20px;
  margin-right: -20px;
  // margin-bottom: 40px;
  @include medium-up {
    margin-left: -50px;
    margin-right: -50px;
    // margin-bottom: 50px;
    // border-bottom: 5px solid white;
  }

  .map {
    border-bottom: 5px solid white;

    .scrollFix {
      h2 {
        margin-bottom: 5px;
        @include fontsize(18 21);
        font-weight: 600;
      }

      p {
        @include fontsize(16 19);
      }
    }
  }

  .location-selector {
    display: none;
  }

  section {
    display: flex;
    flex-wrap: wrap;
  }

  .location {
    padding: 0 20px 40px 20px;
    border-bottom: 5px solid white;
    width: 100%;
    @include medium-up {
      overflow: hidden;
      padding: 0;
      border-width: 2px;
      width: 100%;

      .info {
        padding: 50px;
        width: 50%;
        float: right;
      }
    }

    .image {
      position: relative;
      border-bottom: 5px solid $tallpoppy;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 40px;
      @include medium-up {
        margin: 0;
        float: left;
        width: 50%;
        border-bottom: 0;
        border-right: 5px solid $tallpoppy;
        overflow: hidden;

        img {
          min-width: 100%;
        }
      }

      &:after {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: white;
        content: '';
        @include medium-up {
          bottom: auto;
          top: 0;
          right: 0;
          width: 2px;
          height: 100%;
        }
      }
    }

    ~ .location {
      @include medium-up {
        display: inline-block;
        width: calc(100% / 3);
        border-right: 2px solid white;
        border-bottom: 0;

        &:last-child {
          border-right: 0;
        }

        .info {
          float: none;
          width: 100%;
        }
      }

      .image {
        border-color: $limedspruce;
        @include medium-up {
          float: none;
          width: 100%;
          border-right: 0;
          border-bottom: 5px solid $limedspruce;

          &:after {
            width: 100%;
            height: 2px;
            bottom: 0;
            top: auto;
            right: auto;
          }
        }
      }
    }

    [itemprop='addressLocality'] {
      display: block;
      @include fontsize(18 20);
      font-weight: 700;
      text-transform: uppercase;
    }

    [property='schema:streetAddress'] {
      display: block;
    }

    [property='schema:addressLocality'] {
      clear: left;
    }

    [itemprop='telephone'] {
      display: block;

      strong {
        color: $cobalt;
      }
    }
  }

  .content-form {
    margin: 0;
    @include medium-up {
      border-top: 5px solid white;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}