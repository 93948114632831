table:not(.results) table,
table.tablesaw {
  margin: 0 0 40px 0;
  width: 100%;
  max-width: 100%;
  @include fontsize(15 20);

  thead tr {
    background: $limedspruce;
  }

  tbody tr {
    &:nth-child(odd) {
      background: rgba(white, 0.5);
    }

    &:nth-child(even) {
      background: $lightiron;
    }
  }

  th,
  td {
    padding: 15px 25px;
    border-right: 2px solid $athensgray;
  }
}

@media (max-width: 39.9375em) {
  table.tablesaw-stack {
    margin-bottom: 22px;
    border-top: 5px solid $limedspruce;
    border-bottom: 5px solid $limedspruce;

    tbody tr {
      padding: 20px;
    }

    td {
      margin-bottom: 10px;

      .tablesaw-cell-label {
        display: block;
        width: 100%;
        padding: 0;
      }

      .tablesaw-cell-content {
        display: block;
        padding: 0;
        max-width: 100%;
      }
    }

    th,
    td {
      padding: 0;
      border-right: 0;
    }
  }
}